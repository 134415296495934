import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { FiPrinter, FiDownload} from 'react-icons/fi'; // Import icons
import { FaChevronDown, FaChevronRight, FaCheckCircle,  FaTimes   } from 'react-icons/fa'; // Import the check mark icon

import { SketchPicker} from 'react-color';
import ReactDOM from 'react-dom';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver'; // Optional to simplify download
// reactstrap components
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  CustomInput,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Badge
} from 'reactstrap';
// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import config from '../config';
import GenQRCode from "components/Notes/GenQRCode"
import AuthNavbar from "components/Navbars/AuthNavbar";
import ziplogo from '../assets/img/brand/zipquillrounded.webp';
import imageCompression from 'browser-image-compression';
import QRCodeDownloader from '../components/Utility/QRCodeDownloader'; // Import the QR code downloader component
import AuthFooter from "components/Footers/AuthFooter";



import './UserEditQuill.css';

const UserEditQuill = () => {
  const location = useLocation();
  const [quillChange, setquillChange] = useState(false); // State variable to trigger useEffect
  const { param } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [ advancedOptionsVisible, setAdvancedOptionsVisible] = useState('');
  const [error, setError] = useState(null); // Add error state
  const [guuid, setGuuid] = useState(''); // State variable to store guuid
  const [shortId, setShortId] = useState(''); // State variable for short_id
  const [formLoading, setFormLoading] = useState(true); // State variable to manage form loading
  const [formData, setFormData] = useState({
    friendlyName: '',
    disableAfterRead: false,
    active: false,
    lastSeen: '',
    message: '',
    createdDate: '',
    lastUpdated: '',
    quillSeenCount: 0, // Initialize quillSeenCount in the formData state
  });
  const [imageData, setImageData] = useState()
  const [imageLoading, setImageLoading] = useState(false); // State variable to manage form loading
  const qrCodeRef = useRef(null);
  const [qrColor, setQrColor] = useState('#000000'); // Default color is black
  const [logoColor, setLogoColor] = useState('#000000'); // Default color is black
  const [isPickerVisible, setIsPickerVisible] = useState(false); // Toggle visibility
  const pickerRef = useRef(null); // Ref to the picker component
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentimagefriendly, setcurrentimagefriendly] = useState(null);
 

  // Detect clicks outside of the color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsPickerVisible(false); // Close picker when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  const printQR = () => {
    navigate(`/User/printquill/${param}`, { state: { formData, param, qrColor, logoColor, shortId } });
  };

  useEffect(() => {
    // You can also add logic here to verify or log updated formData if needed
  }, [imageData]);  // This will run whenever formData changes

  useEffect(() => {
    // console.log('useEffect triggered');
    if (location.state && location.state.formData) {
      // Set the form data from the passed state
      setFormData(location.state.formData);
      setGuuid(location.state.param); // Assuming param was also passed
      setShortId(location.state.shortId); // Assuming short_id was also passed
      setQrColor(location.state.qrColor);
      setLogoColor(location.state.logoColor)
      setFormLoading(false); // No need to fetch data, just use the passed state
    } else {
      
    const fetchInitialData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills?quill=${param}`;
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        // console.log(data);

        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        if (Array.isArray(data) && data.length > 0) {
          const quillData = data[0];
          setGuuid(quillData.guuid); // Set the guuid in state
          setShortId(quillData.short_id); // Assuming short_id exists in the response
          setLogoColor(quillData.logoColor || '#000000');
          setQrColor(quillData.qrColor || '#000000');
          setcurrentimagefriendly(quillData.imageFriendly);
          setFormData({
            friendlyName: quillData.friendlyName || '',
            disableAfterRead: quillData.disableAfterRead || false,
            active: quillData.active || false,
            lastSeen: quillData.last_used ? convertUTCToLocal(quillData.last_used) : '',
            message: quillData.message || '',
            lastUpdated: quillData.last_updated ? convertUTCToLocal(quillData.last_updated) : '',
            createdDate: quillData.created_date ? convertUTCToLocal(quillData.created_date) : '',
            quillSeenCount: quillData.quill_seen_count || 0, // Store quill_seen_count in the state
          });
        }
        setFormLoading(false); // Set form loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError(error.message); // Set error message
        setFormLoading(false); // Set form loading to false even if there's an error
      }
    };

    if (param && !formData.friendlyName) {
      fetchInitialData();
    }
  }
  }, [param, getAccessTokenSilently]);

  const convertUTCToLocal = (utcDateTime) => {
    // Parse the UTC date string
    const [datePart, timePart] = utcDateTime.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // Convert to local time
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    return localDate.toISOString().slice(0, 16); // Format for datetime-local input
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
  const MAX_WIDTH = 1024; // Maximum width of the image after compression
  const MAX_HEIGHT = 1024; // Maximum height of the image after compression
  const INITIAL_QUALITY = 0.8; // Starting quality value (will be reduced if needed)
  
  // Function to handle the file selection and compress the image
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setSelectedFile(file);
      let compressedFile = file;
      let quality = INITIAL_QUALITY; // Starting quality
      let iterationCount = 0;
  
      // Loop until the file size is under the max limit (10MB)
      while (compressedFile.size > MAX_FILE_SIZE && iterationCount < 5) {
        try {
          const options = {
            maxWidthOrHeight: Math.max(MAX_WIDTH, MAX_HEIGHT),
            useWebWorker: true,
            quality: quality, // Compress with current quality
          };
  
          // Compress the image
          compressedFile = await imageCompression(file, options);
          
          // Reduce quality for next iteration if file size is still too large
          quality -= 0.1; // Reduce quality
          iterationCount++;
  
          // Break out of loop if quality is too low
          if (quality <= 0.2) {
            alert('Image is still too large after multiple compressions.');
            break;
          }
  
        } catch (error) {
          console.error('Error while compressing the image:', error);
          break;
        }
      }
  
      // Check if the final compressed file size is acceptable
      if (compressedFile.size <= MAX_FILE_SIZE) {
        setFormData({
          ...formData,
          imageFile: compressedFile, // Set compressed file into formData
        });
        console.log('Compressed file size:', compressedFile.size);
      } else {
        alert('Failed to compress the image to a small enough size.');
      }
    }
  };

  // This function handles the image upload process
const handleImageUpload = async (file) => {

  try {
    setImageLoading(true)
    // Get the signed URL from the backend for the image upload
    const token = await getAccessTokenSilently();
    const apiUrl = `${config.apiUrl}/${config.apiStage}/images/signed-url`;
    const signedUrlResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        fileType: file.type, // File type (e.g., 'image/jpeg')
      }),
    });

    const { signedUrl } = await signedUrlResponse.json();

    // If a signed URL is returned, upload the file directly to S3
    if (signedUrl) {
      const uploadResponse = await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
      });

      if (uploadResponse.ok) {
        console.log('File uploaded successfully');

        // Image uploaded successfully, now update the formData with metadata
        const url = new URL(signedUrl); // Create a URL object
        const pathname = url.pathname; // Extract the pathname

        // Extract file path and file name from the pathname
        const filePath = pathname.split('/').slice(0, -1).join('/').replace(/^\//, '');
        const fileName = pathname.split('/').pop(); // The last part is the file name

        console.log("File Path:", filePath);
        console.log("File Name:", fileName);

        const imageMetadata = {
          filePath: filePath,
          fileName: fileName,
          fileFriendly: file.name
        }
        setImageLoading(false)
        return imageMetadata;
      }
      else {
        console.error('Image upload failed');
        return null;
      }
    }
    else {
      console.error('Failed to retrieve signed URL');
      return null;
    }

  } catch (error) {
    console.error('Error during file upload process:', error);
    return null;
  }
};

  const handleSubmit = async (e) => {
    setFormLoading(true)
    e.preventDefault();

    let imageMetadata = null;

    // Check if an image file was selected
    if (formData.imageFile) {
      imageMetadata = await handleImageUpload(formData.imageFile);
      if (!imageMetadata) {
        console.error('Image upload failed');
        setFormLoading(false)
        return;
      }

    } else {
      console.log("No image selected. Proceeding with other form submission logic...");
    }

    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills/${param}`;

      // Ensure formData.message has a default value
      if (!formData.message || formData.message.trim() === "") {
        formData.message = "No message provided... Set your message";
      }

      // Check if imageMetadata is valid before using it
      const hasImageMetadata = imageMetadata && Object.keys(imageMetadata).length > 0;
      
      // If valid imageMetadata exists, prepare it; otherwise, skip it
      const bodyData = {
        ...formData,
        logoColor: logoColor, // Add the logoColor state
        qrColor: qrColor,     // Add the qrColor state
      };

      if (hasImageMetadata) {
        bodyData.imageMeta = imageMetadata;  // Include imageMeta only if it has valid data
        bodyData.imageFriendly = imageMetadata.fileFriendly || currentimagefriendly;  // Use fileFriendly if available
      } else {
        bodyData.imageFriendly = currentimagefriendly;  // Fallback to currentimagefriendly
      }

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),  // Send the data only with the necessary properties
      });

      if (!response.ok) {
        setFormLoading(false)
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      // console.log('Form submitted successfully:', result);
      navigate('/User/Home');
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormLoading(false)
    }
  };

  const handleDelete = async (guuid) => {
    const confirmDelete = window.confirm("Confirm delete this quill?");
    if (!confirmDelete) {
      return;
    }
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/userquills/${guuid}`;
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // console.log('Delete response:', data);
      setquillChange(prev => !prev); // Trigger the useEffect to run again
      navigate('/User/Home');
    } catch (error) {
      console.error('Delete API call failed:', error);
    }
  };

  const handleCancel = () => {
    // Navigate to the static page /User/Home
    navigate('/User/Home');
    // navigate(-1); // Navigate back to the previous page
  };

  const handleView = () => {
    const url = `/q/${param}`;
    window.open(url, '_blank');
  };

  const handleChangeColorQR = (color) => {
    // Check if the alpha value exists (for RGBA support)
    const { r, g, b, a } = color.rgb;
    
    // If alpha exists, we return an RGBA value
    if (a !== undefined) {
      setQrColor(`rgba(${r}, ${g}, ${b}, ${a})`);
    } else {
      // Otherwise, store it as RGB
      setQrColor(`rgb(${r}, ${g}, ${b})`);
    }
  };
  
  const handleChangeColorLogo = (color) => {
    // Check if the alpha value exists (for RGBA support)
    const { r, g, b, a } = color.rgb;
    
    // If alpha exists, we return an RGBA value
    if (a !== undefined) {
      setLogoColor(`rgba(${r}, ${g}, ${b}, ${a})`);
    } else {
      // Otherwise, store it as RGB
      setLogoColor(`rgb(${r}, ${g}, ${b})`);
    }
  };
  // Toggle the color picker visibility on row click
  const togglePicker = () => {
    setIsPickerVisible(!isPickerVisible);
  };

  const fileName = `zipquill-${formData.friendlyName || 'quill'}.png`;

  if (error) {
    return ( // Use return here to properly return the JSX
      <>
        <div className="main-content">  
          <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
                <Col className="text-center">
                  <div className="text-muted">Error: {error}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (isLoading || formLoading) {
    return (
        <>
        <div className="main-content">  
        <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">
                  {imageLoading ? "Uploading image..." : "Loading..."}
                </div>
              </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-content">  
        <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container className="d-flex flex-column align-items-center mt-6">
            <Row onClick={togglePicker}>
              <div className="qr-button" ref={qrCodeRef}>
                <GenQRCode qrColor={qrColor} size={128} logoColor={logoColor} quill={shortId} friendlyName={formData.friendlyName} />
              </div>
            </Row>

            {/* Collapsible color picker */}
            {isPickerVisible && (
              <Row className="mb-2"> {/* Row for the color pickers */}
                <Col className="d-flex flex-column align-items-center mt-3">
                  <h6 className="text-white">QR Color</h6>
                  <SketchPicker 
                    color={qrColor} 
                    onChangeComplete={handleChangeColorQR} 
                    disableAlpha={true}  // Disable the alpha (transparency) slider
                  />
                </Col>
                <Col className="d-flex flex-column align-items-center mt-3 ">
                  <h6 className="text-white">Logo Color</h6>
                  <SketchPicker 
                    color={logoColor} 
                    onChangeComplete={handleChangeColorLogo} 
                    disableAlpha={true}  // Disable the alpha (transparency) slider
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-1 text-white">
              <small className="">{formData.friendlyName || "Set Friendly Name"}</small>
            </Row>
            <Row className="mt-4 mb-2 text-white qr-buttons-container">
              <Button className="p-1" onClick={printQR} style={{ width: '140px', height: '40px' }}>
                <FiPrinter style={{ marginRight: '8px', size:'24px' }} />Print
              </Button>
              {/* <Button onClick={downloadQRCodeWithLogo} style={{ width: '140px', height: '40px' }}>
                <FiDownload style={{ marginRight: '8px' }} />Download
              </Button> */}
              <QRCodeDownloader qrCodeRef={qrCodeRef} fileName={fileName} scale={6} />
            </Row>
          </Container>

          <Container className="mt-2">
  <Card color="secondary" className="my-1">
    <CardBody>
      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-5">
          <Label for="friendlyName" sm={2}>Friendly Name</Label>
          <Col sm={10}>
            <Input
              type="text"
              name="friendlyName"
              id="friendlyName"
              placeholder="Enter friendly name"
              value={formData.friendlyName}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>

       
       {/* Image Upload Section */}
      <FormGroup row className="mb-4">
        <Col sm={{ size: 10, offset: 2 }}>
        <Button
          color="primary"
          onClick={() => document.getElementById('imageFile').click()}
          className="d-flex align-items-center"
        >
          {selectedFile || currentimagefriendly ? (
            <>
              {selectedFile ? (
                <>
                  <FaCheckCircle style={{ marginRight: '8px' }} />
                  {selectedFile.name}
                </>
              ) : (
                currentimagefriendly
              )}
              {/* Replace the Button with a span or div */}
              <FaTimes
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the file input click
                  setSelectedFile(null); // Clear the selected file
                  setcurrentimagefriendly(null); // Clear the selected file
                  document.getElementById('imageFile').value = ''; // Reset the file input
                }} 
                style={{
                  marginLeft: '10px', 
                  cursor: 'pointer', 
                  color: 'red', 
                  fontSize: '18px' // Adjust the size of the icon
                }}
              />
            </>
          ) : (
            'Upload Image' // Display this if neither selectedFile nor currentimagefriendly is available
          )}
        </Button>

          <Input
            type="file"
            name="imageFile"
            id="imageFile"
            accept="image/*"
            onChange={(e) => {
              handleFileChange(e);
              setSelectedFile(e.target.files[0]);
            }}
            style={{ display: 'none' }}
          />
        </Col>
      </FormGroup>



        <FormGroup row className="mb-1 mt-4">
          <Label for="message" sm={2}>Message</Label>
          <Col sm={10}>
            <Input
              type="textarea"
              name="message"
              id="message"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              maxLength={3000}
              style={{ height: '200px' }}
            />
            <div className="text-muted" style={{ textAlign: 'right' }}>
              {formData.message.length}/3000
            </div>
          </Col>
        </FormGroup>
         {/* Advanced Options Dropdown */}
         <div className="mb-4" onClick={() => setAdvancedOptionsVisible(!advancedOptionsVisible)} style={{ cursor: 'pointer', color: '', display: 'flex', alignItems: 'center' }}>
          <span>{advancedOptionsVisible ? 'Advanced Options' : 'Advanced Options'}</span>
          <span style={{ marginLeft: '8px' }}>{advancedOptionsVisible ? <FaChevronDown size={15} /> : <FaChevronRight size={15} />}</span>
        </div>

        {advancedOptionsVisible && (
          <div className="advanced-options">
            <FormGroup row className="mb-1 align-items-center">
              <Label for="disableAfterRead" sm={2}>Disable after Read</Label>
              <Col sm={10}>
                <CustomInput
                  type="switch"
                  id="disableAfterRead"
                  name="disableAfterRead"
                  checked={formData.disableAfterRead}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row className="mb-4 align-items-center">
              <Label for="active" sm={2}>Active</Label>
              <Col sm={10}>
                <CustomInput
                  type="switch"
                  name="active"
                  id="active"
                  checked={formData.active}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>



            <FormGroup row className="mb-3 mt-3">
              <Label sm={2} className="pb-0">Views
                <div className="smaller-text">(resets to '0' with any change or update)</div>
              </Label>
              <Col sm={10}>
                <h1>
                  <Badge color="primary" className="view-count-badge">
                    {formData.quillSeenCount}
                  </Badge>
                </h1>
              </Col>
            </FormGroup>

            <FormGroup row className="mb-1">
              <Label for="lastSeen" sm={2}>Last Seen</Label>
              <Col sm={10}>
                <Input
                  type="datetime-local"
                  name="lastSeen"
                  id="lastSeen"
                  value={formData.lastSeen}
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row className="mb-1 ">
              <Label for="lastUpdated" sm={2}>Last Updated</Label>
              <Col sm={10}>
                <Input
                  type="datetime-local"
                  name="lastUpdated"
                  id="lastUpdated"
                  value={formData.lastUpdated}
                  readOnly
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="createdDate" sm={2}>Created Date</Label>
              <Col sm={10}>
                <Input
                  type="datetime-local"
                  name="createdDate"
                  id="createdDate"
                  value={formData.createdDate}
                  readOnly
                />
              </Col>
            </FormGroup>
          </div>
        )}


                  <Row className="align-items-center d-none d-md-flex">
                    <Col className="d-flex justify-content-start flex-grow-1">
                      <Button type="submit" color="primary" className="responsive-button mr-2">Submit</Button>
                      <Button color="info" onClick={handleView} className="responsive-button dark">View</Button>
                      <Button color="secondary" onClick={handleCancel} className="responsive-button dark">Cancel</Button>
                    </Col>
                    <Col className="d-flex justify-content-end flex-shrink-1">
                      <Button color="danger" onClick={() => handleDelete(param)} className="responsive-button">Delete</Button>
                    </Col>
                  </Row>

                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>

      {/* Fixed footer for mobile */}
      <div className="fixed-footer d-md-none">
        <Container>
        <Row className="align-items-center">
          <Col>
            <Button 
              color="info" 
              onClick={handleView} 
              className="responsive-button dark w-100"
            >
              View
            </Button>
          </Col>
        </Row>
          <Row className="align-items-center mt-4">
            <Col className="d-flex justify-content-start flex-grow-1">
              <Button type="submit" color="primary" className="responsive-button mr-2" onClick={handleSubmit}>Submit</Button>
              <Button color="secondary" onClick={handleCancel} className="responsive-button dark">Cancel</Button>
            </Col>
            <Col className="d-flex justify-content-end flex-shrink-1">
              <Button color="danger" onClick={() => handleDelete(param)} className="responsive-button">Delete</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserEditQuill;
