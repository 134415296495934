import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col, Spinner } from "reactstrap";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthenticationAndPrivacy = async () => {
      if (!isLoading && !isAuthenticated) {
        // If not authenticated, store the attempted URL and redirect to login
        const returnTo = location.pathname + location.search;
        localStorage.setItem('redirectUrl', returnTo);
        loginWithRedirect();
        return;
      }

      if (isAuthenticated) {
        try {
          // Attempt to renew the token silently first
          // console.log("Attempting to renew token silently...");
          await getAccessTokenSilently();
          // console.log("Token successfully renewed.");

          // After token renewal, retrieve ID token claims to check privacy policy status
          const claims = await getIdTokenClaims();
          const privacyPolicyStatus = claims['privacypolicystatus']; // Custom claim for privacy policy

          // console.log('Privacy Policy Status:', privacyPolicyStatus);

          if (privacyPolicyStatus === false) {
            // If privacy policy is not accepted, redirect to privacy page
            let returnTo = localStorage.getItem('redirectUrl');
            
            // If returnTo is null, set it to '/User/Home'
            if (!returnTo) {
              returnTo = '/User/Home';
            }
            localStorage.setItem('redirectUrl', returnTo); // Store attempted URL before redirect

            navigate('/privacy');
          } else {
              // console.log(location.pathname)
              // If privacy policy is accepted, continue with the regular flow
              const redirectUrl = localStorage.getItem('redirectUrl');
              if (redirectUrl) {
                // Redirect to the original URL if stored
                localStorage.removeItem('redirectUrl');
                navigate(redirectUrl);
              }
          }
        } catch (error) {
          // console.error('Token renewal failed or error fetching claims', error);
          loginWithRedirect(); // If error occurs, redirect to login
        }
      }
    };

    checkAuthenticationAndPrivacy();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, navigate, location]);

  if (isLoading) {
    return (
      <div className="main-content">
        <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
          <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
            <Row>
              <Col className="text-center">
                <Spinner color="info" />
                <div className="text-muted">Loading...</div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  // If authenticated and no privacy policy issue, render the component
  return isAuthenticated ? <Component {...rest} /> : null;
};

export default ProtectedRoute;
