import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col, Card, CardBody, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input } from "reactstrap";
import { FaTimes } from 'react-icons/fa'; // Import FaTimes for the "X" icon
import AuthNavbar from "components/Navbars/AuthNavbar";
import config from '../config';
import QRCodeDownloader from '../components/Utility/QRCodeDownloader'; // Import the QR code downloader component
import GenQRCode from "components/Notes/GenQRCode";

const AdminHome = (props) => {
  const mainContent = React.useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedQuills, setSelectedQuills] = useState([]);
  const [guuidFilter, setGuuidFilter] = useState("");  // State for guuid filter
  const { getAccessTokenSilently } = useAuth0();
  
  const qrCodeRefs = useRef([]);  // This will hold the refs for each QR code
  
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  // Fetching data as before
  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/admin/adminstats`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      // Transform uniqueOwners to replace null with "NoOwner"
      const transformedUniqueOwners = result.uniqueOwners.map(owner => owner || "NoOwner");
      result.uniqueOwners = transformedUniqueOwners;

      // Transform quills to replace null owner with "NoOwner"
      result.mostRecentQuills = result.mostRecentQuills.map(quill => ({
        ...quill,
        owner: quill.owner || "NoOwner"
      }));

      setData(result);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getAccessTokenSilently]);

  const handleDelete = async () => {
    // Add confirmation prompt
    if (!window.confirm(`Delete ${selectedQuills.length} quill(s)?`)) {
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const apiUrl = `${config.apiUrl}/${config.apiStage}/admin/quills`;

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ guuids: selectedQuills })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // After successful delete, refresh the data
      setLoading(true);
      setSelectedQuills([]);  // Clear selection
      fetchData();  // Call fetchData to refresh the data
    } catch (error) {
      console.error('Error deleting quills:', error);
    }
  };

  const handleSelectQuill = (guuid) => {
    setSelectedQuills(prevState => {
      if (prevState.includes(guuid)) {
        return prevState.filter(id => id !== guuid);
      } else {
        return [...prevState, guuid];
      }
    });
  };

  const handleOwnerDeselect = () => {
    setSelectedOwner(null);  // This will deselect the owner
  };

  if (loading) {
    return (
      <>
        <div className="main-content" ref={mainContent}>
          <AuthNavbar />
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
                <Col className="text-center">
                  <Spinner color="info" />
                  <div className="text-muted">Loading...</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (!data) {
    return <div>No data available</div>;
  }

  // Filter by owner if selected
  let filteredQuills = selectedOwner
    ? data.mostRecentQuills.filter(quill => quill.owner === selectedOwner)
    : data.mostRecentQuills;

  // Apply guuid filter
  if (guuidFilter) {
    filteredQuills = filteredQuills.filter(quill => quill.guuid.toLowerCase().includes(guuidFilter.toLowerCase()));
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container className="mt-7">
            <h1 className="text-center text-light">Overall Quill Stats</h1>
            {/* First Row: Unique Owners Count and Total Quill Count */}
            <Row className="mb-2">
              <Col>
                <Card className="text-center">
                  <CardBody>
                    <h5>Unique Owners Count: {data.uniqueOwnersCount}</h5>
                    <h5>Total Quill Count: {data.totalQuillCount}</h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Filters Row: User Dropdown and Guuid Search */}
            <Row className="mb-2">
              <Col>
                <Card className="text-center">
                  <CardBody>
                    <h5>Select User</h5>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle caret color="info">
                        {selectedOwner || "Select an Owner"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {data.uniqueOwners.map((owner, index) => (
                          <DropdownItem key={index} onClick={() => setSelectedOwner(owner)}>
                            {owner}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                    {/* "X" icon to deselect the owner */}
                    {selectedOwner && (
                      <Button
                        color="link"
                        className="ml-2"
                        onClick={handleOwnerDeselect}
                        style={{ fontSize: '20px', color: 'red' }}
                      >
                        <FaTimes />
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            <Row className="mb-4">
              <Col>
                <Card className="text-center">
                  <CardBody>
                    <h5>Search by Quill ID (guuid)</h5>
                    <Input
                      type="text"
                      value={guuidFilter}
                      onChange={(e) => setGuuidFilter(e.target.value)}
                      placeholder="Enter Quill ID"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h1 className="text-center text-light">Most Recent Quills (Top 200)</h1>

            {/* Delete Button */}
            <Row className="mb-4">
              <Col className="text-center">
                <Button color="danger" onClick={handleDelete} disabled={selectedQuills.length === 0}>
                  Delete Selected Quills ({selectedQuills.length})
                </Button>
              </Col>
            </Row>

            {/* Display Most Recent Quills */}
            {filteredQuills.map((quill, index) => {
              qrCodeRefs.current[index] = qrCodeRefs.current[index] || React.createRef();  // Initialize ref for each QR code

              return (
                <Row key={quill.guuid}>
                  <Col>
                    <Card className="my-1">
                      <CardBody>
                        <Input
                          type="checkbox"
                          checked={selectedQuills.includes(quill.guuid)}
                          onChange={() => handleSelectQuill(quill.guuid)}
                          className="mr-2"
                        />
                        <div>
                          <strong>{quill.owner}</strong>
                        </div>
                        <div><br /></div>
                        <div>
                          <label>Quill ID:</label> <span>{quill.guuid}</span>
                        </div>
                        <div>
                          <label>Message:</label>
                          <p>{quill.message}</p>
                        </div>
                        <div>
                          <label>Image Path:</label> <small>{quill.image_path}</small>
                        </div>
                        <div>
                          <label>Last Updated:</label> <small>{quill.last_updated}</small>
                        </div>
                        {/* Generate QR Code as an Image */}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <GenQRCode
                            ref={qrCodeRefs.current[index]}
                            qrColor={quill.qrColor}
                            size={128}
                            logoColor={quill.logoColor}
                            quill={quill.short_id}
                            friendlyName={quill.friendlyName}
                          />
                        </div>
                        {/* QR Code Download */}
                        <QRCodeDownloader
                          qrCodeRef={qrCodeRefs.current[index]}  
                          fileName={quill.guuid}
                          scale={6}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
