import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import GenQRCode from 'components/Notes/GenQRCode';

import './UserPrintQuill.css'; // Ensure this is imported to apply styles

const UserPrintQuill = () => {
  const location  = useLocation();
  const navigate = useNavigate(); // useNavigate hook to programmatically navigate
  const { formData, param, qrColor, logoColor, shortId } = location.state || {}; // Retrieve the entire state

  const rows = 6;
  const cols = 4;
  const qrSize = 1.6 * 96; // 1.5 inches to pixels (1 inch = 96 pixels)

  useEffect(() => {
    // Trigger the print dialog
    window.print();

    // Add a focus event listener to detect when the user returns to the page after printing
    const handleFocus = () => {
      navigate(`/User/editquill/${param}`, { state: { formData, param, qrColor, logoColor, shortId },
      replace: true,
       }); // Navigate back to the previous page
    };

    window.addEventListener('focus', handleFocus);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [param, location, navigate]);


  const renderQRCodes = () => {
    const defaultFriendlyName = "Set a Friendly Name";
    let qrCodes = [];
    for (let i = 0; i < rows * cols; i++) {
      qrCodes.push(
        <div key={i} className="qr-container" style={{ width: qrSize }}>
        <div className="qr-friendly-name">
            ZipQuill.com
        </div>
        <GenQRCode qrColor={qrColor} logoColor={logoColor} quill={shortId} size={qrSize} friendlyName={formData?.friendlyName || defaultFriendlyName} />
        <div className="qr-friendly-name">
        {formData?.friendlyName || defaultFriendlyName}
        </div>
        </div>
      );
    }
    return qrCodes;
  };

  return (
    <div className="print-page" style={{ display: 'grid', gridTemplateColumns: `repeat(${cols}, 1fr)`, gap: '18px 90px'  }}>
      {renderQRCodes()}
    </div>
  );
};

export default UserPrintQuill;
