import React, { forwardRef } from 'react';
import QRCode from 'qrcode.react';
import { ReactComponent as ZipLogo } from '../../assets/img/brand/ZipQuillTransparent.svg';

const GenQRCode = forwardRef(({ quill, size, qrColor = '#000000', logoColor = '#000000' }, ref) => {
  const fullUrl = quill ? `https://zipquill.com/q/${quill}` : null;

  // Set the logo size to be a percentage of the QR code size (e.g., 20% of the QR code size)
  const logoSize = size * 0.4;

  // Set the resolution multiplier
  const resolutionMultiplier = 8;

  return (
    <div 
      ref={ref} 
      style={{
        textAlign: 'center', 
        position: 'relative', 
        display: 'flex',
        padding: '3px',  // Padding around the QR code
        borderRadius: '8px',  // Rounded edges for the outer container
        backgroundColor: '#f2f2f2',  // Optional background color
      }}
    >
      {/* QR code with rounded edges */}
      <QRCode
        id="qr-code"
        value={fullUrl}
        size={size * resolutionMultiplier}  // Size of the QR code
        level="H"  // Error correction level
        fgColor={qrColor}  // QR code color
        style={{
          width: `${size}px`,  // Visually display the QR code at the original size
          height: `${size}px`,
          borderRadius: '5px',  // Rounded corners for the QR code itself
        }}
      />

      {/* Logo in the center of the QR code */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: `${logoSize}px`,  // Dynamic logo size based on QR code size
          height: `${logoSize}px`,
          borderRadius: '50%',
          overflow: 'hidden',
          backgroundColor: 'white',  // Optional white background for the logo
          padding: '0%',
        }}
      >
        <ZipLogo
          style={{
            width: '100%',
            height: '100%',
            color: logoColor  // Logo color (if applicable)
          }}
        />
      </div>
    </div>
  );
});

export default GenQRCode;
