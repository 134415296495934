import { useState, useEffect } from 'react';

const MaintenanceBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const now = new Date();
    const utcHour = now.getUTCHours();
    const estHour = (utcHour - 5 + 24) % 24; // Convert UTC to EST

    if (estHour >= 20 || estHour < 6) {
      setIsVisible(true); // Show the banner only during the maintenance hours
    }
  }, []);

  const closeBanner = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#f44336',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        zIndex: 1000,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'auto',
        maxWidth: '90%',
        boxSizing: 'border-box',
      }}
    >
      <span>
        Site maintenance in progress from 9:00 PM to 7:00 AM EST.  <br /> <br />If an error occurs, please wait 3 minutes and try again.
      </span>
      <button
        onClick={closeBanner}
        style={{
          background: 'none',
          border: 'none',
          color: 'white',
          fontSize: '18px',
          cursor: 'pointer',
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default MaintenanceBanner;
