import React, { useRef } from 'react';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import { FiDownload} from 'react-icons/fi'; // Import icons


const QRCodeDownloader = ({ qrCodeRef, fileName, scale = 6 }) => {
  // Function to download the QR code as an image with logo
  const downloadQRCodeWithLogo = () => {
    if (!qrCodeRef.current) {
      console.error('qrCodeRef.current is null or undefined.');
      return;
    }

    // Use `dom-to-image` to convert the component to an image with higher resolution
    domtoimage.toBlob(qrCodeRef.current, {
      width: qrCodeRef.current.offsetWidth * scale, // Scale the width
      height: qrCodeRef.current.offsetHeight * scale, // Scale the height
      style: {
        transform: `scale(${scale})`, // Apply the scaling to the content
        transformOrigin: 'top left', // Set origin to top-left to avoid distortion
        width: qrCodeRef.current.offsetWidth + 'px', // Keep the original width and height styling
        height: qrCodeRef.current.offsetHeight + 'px'
      }
    })
      .then(function (blob) {
        // Set the file name dynamically if not provided
        const downloadFileName = fileName || 'qr-code.png';
      
        // Use `saveAs` from `file-saver` to trigger a download
        saveAs(blob, downloadFileName);
      })
      .catch(function (error) {
        console.error('An error occurred while capturing the QR code:', error);
      });
  };

  return (
    <button onClick={downloadQRCodeWithLogo} className="btn btn-secondary">
      <FiDownload style={{ marginRight: '8px' }} />Download
    </button>
  );
};

export default QRCodeDownloader;
