import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { useAuth0 } from '@auth0/auth0-react';
import './Profile.css'; // Ensure this is imported to apply styles
import config from '../config';
import AuthFooter from "../components/Footers/AuthFooter"


const Profile = () => {
  const { user, getIdTokenClaims, getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
  const [idTokenClaims, setIdTokenClaims] = useState(null);
  const [stats, setStats] = useState(null); // State for stats data
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchIdTokenClaims = async () => {
      const claims = await getIdTokenClaims();
      setIdTokenClaims(claims);
    };

    if (!isLoading && isAuthenticated) {
      fetchIdTokenClaims();
    }
  }, [getIdTokenClaims, isLoading, isAuthenticated]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = await getAccessTokenSilently();
        const email = user.email;
        const apiUrl = `${config.apiUrl}/${config.apiStage}/userstats`; // Updated API route

        const response = await fetch(apiUrl, {
          method: 'GET', // Assuming the API uses POST; change to GET if needed
          headers: {
            Authorization: `Bearer ${token}`,
          },
     
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const data = await response.json();
        setStats({
          total_quills: data.total_quills,
          active_quills: data.active_quills,
          total_quill_seen_count: data.total_quill_seen_count,
          max_quills: data.max_quills,
        });

      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(error.message); // Set error message

      }
    };

    if (!isLoading && isAuthenticated && user) {
      fetchStats();
    }
  }, [getAccessTokenSilently, user, isAuthenticated, isLoading]);

  if (isLoading || !user || !stats) {
    return (
      <>
       
          <div className="header bg-gradient-info pb-8 pt-7 pt-md-8">
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
              <Row>
              <Col className="text-center">
                <Spinner color="info" />  {/* 'info' is lighter than 'primary' */}
                <div className="text-muted">Loading...</div>
              </Col>
              </Row>
            </Container>
          </div>
   
      </>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={user.picture}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              
              <CardBody className="pt-0 pt-md-4">
                <Row className="justify-content-center">
                  <h3 className="mt-4 d-flex justify-content-center mt-md-5">Stats & Settings</h3>
                </Row>
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-0">
                      <div>
                        <span className="heading">{stats.active_quills}</span>
                        <span className="description">Active Quills</span>
                      </div>
                      <div>
                        <span className="heading">{stats.total_quills}</span>
                        <span className="description">Total Quills</span>
                      </div>
                      <div>
                        <span className="heading">{stats.max_quills}</span>
                        <span className="description">Max Quills</span>
                      </div>
                      <div>
                        <span className="heading">{stats.total_quill_seen_count}</span>
                        <span className="description">Seen Count</span>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  {/* <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.nickname || ""}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.email || ""}
                            id="input-email"
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={idTokenClaims?.given_name || ""}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={idTokenClaims?.family_name || ""}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Additional fields */}
                  
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AuthFooter />
    </>
  );
};

export default Profile;
