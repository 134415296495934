global.cfUrl  = process.env.REACT_APP_CFURL
const config = {
    apiUrl: 'https://4ve4ucx3zf.execute-api.us-east-1.amazonaws.com',
    apiStage: 'v1',
    auth0: {
      domain: 'dev-nfmgw5fwvjeau2ay.us.auth0.com',
      clientId: 'KSGpu0hxFoEhly30yp8FXnKbC2kCkHb7',
      audiance: 'https://api.zipquill.com',
      oidcscopes: "openid profile email read:current_user update:current_user_metadata"
    },
  };

  export default config